const en_US = {
  fundraisingPool: 'Pool',
  farm: 'Farms',
  bridge: 'Migrate',
  mortgage: 'collateral mining',
  liquidityPool: 'liquidity mining',
  crowdfund: 'Crowdfunding Pool',
  projectApplication: 'IWO(DAO)',
  projectApplicationLink: 'Application',
  linkWallet: 'Connect wallet',
  coinCollar: 'Cumulative coin-days',
  transactionSuccess: 'transaction completed',
  transactionFail: 'transaction failed',
  noNetwork: 'transaction failed',
  cannotSubscribe: 'Unavailable to purchase currently',
  bannerTitle: 'Liquidity Valuation Discovery Platform',
  bannerContent1:
    'WeStarter is a cross-chain token initial swap platform which based on HECO network. Through the fluency of the product and sufficient industry resources, it carries the requirement of resources for a variety of asset types. With the role of gatekeeper, through the decentralized incentive and governance mechanism of tokens, to achieve the goal of selecting high-quality assets.',
  bannerContent2: '',
  money: 'input investment amount',
  FundraisingRecord: 'Fundraising Record',
  invest: 'Investment ',
  num: 'Amount',
  winningRate: 'Expected Winning Rate',
  winningAmount: 'Expected Share',
  unsettlement: 'Unsettled',
  obtain: 'Obtain ',
  comingSoon1: 'coming soon',
  recruit: 'Undergoing',
  recruitOver: 'Waiting',
  settlement: 'Calculating',
  completed: 'Finished',
  settled: '已结算',
  waitingSettlement: '等待结算',
  totalRaised: 'Total funds will be raised',
  LBPSupply: 'LBP supply',
  totalMortgage: 'total assets collateralized',
  myMortgage: 'my collateral',
  balance: 'balance',
  income: 'revenue',
  appoveStake: 'Approve Stake',
  claim: 'Claim',
  unstakeClaim: 'Unstake &Claim',
  recruitMaterials: 'fundraising application information',
  projectTitle: 'project title',
  role: 'your role/position in the project',
  email: 'email',
  telegramID: 'your Telegram ID',
  openSubscription: 'Whend do you plan to purchase?',
  raiseMoney: 'How much funds do you aim to raise?',
  raiseCoin: 'What is your ideal token type?',
  subscriptionRatio: 'What is your preferred purchase ratio?',
  projectInstruction: 'About the Project',
  introduction: 'Introduction, website and URL of the official accaount',
  projectModel: 'The Economic model of the token',
  modelTip: 'Financing progress, token distribution and application scenarios',
  shareFile: 'Documents you would like to share with us',
  uploadFile: 'click the button or drag the file here to upload',
  support: 'support extension:',
  tip:
    'Reminder: To participate in fundraising, the project owner shall submit documents (e.g., code auditing reports, legitimacy proof which can be uploaded in "other files") to prove the project is secure. ',
  submit: 'submit',
  errorTip: 'Please fill in this blank.',
  myJoinPool: 'My Pool',
  bannerTitle1: 'Get the Drop on Spotting the Value',
  certik: 'Audit Report',
  partner: 'Business Partner',
  noData: 'No Internet Connection',
  guide: 'Tutorial',
  productManual: 'Guide',
  tutorial: 'Tutorial',
  poolsDetailText1: 'Swap Amount',
  poolsDetailText2: 'Swap Progress',
  poolsDetailText3: 'Join Pool',
  poolsDetailText4: 'View HECO',
  poolsDetailText400: 'View BSC',
  poolsDetailText4000: 'View Avalanche',
  poolsDetailText5: 'Claim',
  poolsDetailText6: 'Pool Details',
  poolsDetailText7: 'About the Project',
  poolsDetailText8: 'Pool Information',
  poolsDetailText9: 'Token Distribution',
  poolsDetailText10: 'Allocation(Min)-Allocation(Max)',
  poolsDetailText11: 'Hard Cap',
  poolsDetailText12: 'Token Information',
  poolsDetailText13: 'Name',
  poolsDetailText14: 'Address',
  poolsDetailText15: 'Total Supply',
  poolsDetailText16: 'Duration',
  poolsDetailText17: 'Total applicants',
  poolsDetailText18: 'Winning rate',
  poolsDetailText19: 'Committed',
  poolsDetailText20: 'Total',
  poolsDetailText21: 'Special',
  poolsDetailText22:
    "FYI: HCT hasn't officially launched on-chain with uncertainty in. Westarter will not be held responsible for any investment losses.",
  poolsIndexText1: 'Ratio',
  poolsIndexText2: 'Progress',
  poolsIndexText3: 'Enter Pool',
  poolsIndexText4: 'Top Pools',
  stakingSuccessText1: 'You have successfully staked',
  stakingSuccessText2: '100.222345 GLF',
  stakingSuccessText3: 'Ok',
  accountText1: 'by',
  accountText2: 'Redeemed Jan 15, 2020',
  accountText3: 'Reward points',
  accountText4: 'GLF Votes',
  accountText5: 'Token ID',
  accountText6: 'METAMASK',
  accountText7: 'WalletConnect',
  accountText8: 'Connect to your Metamask Wallet',
  accountText9: 'Connect to your WalletConnect',
  auctionText1: 'Purchased',
  bannerText1: 'Live on',
  exhibitionHallText1: 'Become a sponsor',
  exhibitionHallText2:
    'You can sponsor an artist to publish his artwork here on Exhibition hall',
  exhibitionHallText3: 'Join a Contest',
  exhibitionHallText4:
    "Independent digital art gallery 'Aadvark' statrs a new digital artwork contest",
  exhibitionHallText5: 'NFT for free!',
  exhibitionHallText6:
    'Publish your artwork this weekend and get NFT token for it - for free!',
  modalsText1: 'You Must Change Networks',
  modalsText2:
    "We've detected that you need to switch your wallet's network from local to mainnet network for this Dapp.",
  modalsText3:
    '*Some wallets may not support changing networks. If you can not change networks in your wallet you may consider switching to a different wallet.',
  modalsText4: 'Switch Wallet',
  modalsText5: 'Dismiss',
  modalsText6: 'You have successfully claimed',
  modalsText7: 'Claim Reward',
  modalsText8: 'Your reward pool:',
  modalsText9: 'Current staking time:',
  modalsText10: 'hours',
  modalsText11:
    'You will get { data1 } GLF { data2 }% of your GLF will be burned) if you will claim your reward now',
  modalsText12:
    'If you claim GLF from the rewards pool before a10-day staking period is over, part of your reward will be subject to burn. The burn rate is as follows',
  modalsText13: 'I understand, claim my reward anyway',
  modalsText14: 'Cancel',
  modalsText15: 'Confirm',
  modalsText16: 'Please connect wallet to Ethereum Main Network',
  modalsText17: 'Create an Artwork Preview',
  modalsText18: 'Apply',
  modalsText19: 'Transaction failed, please try again',
  modalsText20: 'Close',
  modalsText21: 'Login and Authorize Your wallet',
  modalsText22:
    'This dapp requires access to your wallet,please login and authorize access to your MetaMask accounts to continue',
  modalsText23: 'Dismiss',
  modalsText24: 'Propose a New Figure',
  modalsText25: 'Note: no more than 200 caharcters',
  modalsText26:
    'Please describe the person and attach his linkedin or social media links where artists can find photos.',
  modalsText27:
    'You need to stake <b>50 GLF tokens</b> to proposea figure and tokens will be staked for 4 days.',
  modalsText28: 'You can propose more than one figure',
  modalsText29: 'Submit',
  modalsText30: 'Token ID:',
  modalsText31: 'Name:',
  modalsText32: 'Details:',
  modalsText33: 'OK',
  modalsText34: 'Redeem an Artwork',
  modalsText35: 'Name:',
  modalsText36: 'Details:',
  modalsText37: 'Reward Points will be spent to redeem this artwork. You have',
  modalsText38: 'Reward Points now.',
  modalsText39: 'Cancel',
  modalsText40: 'Redeem',
  modalsText41: 'You have successfully staked',
  modalsText42: 'OK',
  modalsText43: 'Deposit LP Token',
  modalsText44: 'SLP Available',
  modalsText45: 'Max',
  modalsText46: 'Cancel',
  modalsText47: 'Confirm',
  modalsText48: 'Congratulations!',
  modalsText49: 'OK',
  modalsText50: 'Transation rejected',
  modalsText51: 'Confirm',
  modalsText52: 'Your tokens were successfully unlocked',
  modalsText53: 'OK',
  modalsText54: 'Unstake',
  modalsText55: 'Max',
  modalsText56: 'Balance:',
  modalsText57: 'Cancel',
  modalsText58: 'Confirm',
  modalsText59: 'ACCOUNT',
  modalsText60: 'Copy Address',
  modalsText61: 'Switch Wallet',
  modalsText62: 'Disconnect Wallet',
  modalsText63: 'Withdraw',
  modalsText64: 'Deposited',
  modalsText65: 'Your Unclaimed WAR',
  modalsText66: 'Unstake &Claim',
  modalsText67: 'Approve failed, Please try again',
  modalsText68: 'Succeeded! Please return to the page to view',
  poolText1: 'by',
  poolText2: 'Token ID:',
  poolText3: 'Reward points',
  poolText4: 'Stake',
  poolText5: 'Earn Reward points',
  poolText6: 'to redeem NFTs',
  poolText7: 'Staked',
  poolText8: 'Reward Points',
  poolText9: 'NFTs redeemed',
  poolText10: 'NFTs left',
  poolText11: 'Stake',
  poolText12: 'Earn GLF',
  poolText13: 'Staked',
  poolText14: 'Pool total',
  poolText15: 'Time left',
  poolText16: 'Join ',
  poolText17: ' Pool',
  poolText18: 'Balance:',
  poolText19: 'Max',
  poolText20: 'Cancel',
  poolText21: 'Approve',
  poolText22: 'Confirm',
  poolText23: 'Max quota:',
  poolTextS128: 'Switch to HECO',
  poolTextS137: 'Switch to Matic',
  poolTextS56: 'Switch to BSC',
  poolTextS43114: 'Switch to Avalanche',
  poolText27: 'Switch success',
  workShopText1: 'Time in',
  workShopText2: 'Status',
  workShopText3: 'by',
  workShopText4: '#vangogh',
  workShopText5: 'Vote',
  workShopText6: 'GLF Votes',
  workShopText7: 'Vote',
  backbutton: 'Back',
  transaction1: 'Waiting For Confirmation',
  transaction2: 'Confirm this transaction in your wallet',
  noJoinPool: "You haven't joined any pool",
  comingSoon: 'Coming soon',
  htPublic: 'HT Public',
  detailTitlePrivate: 'Private Pool',
  detailTitlePublic: 'Public Pool',
  logoTitle1: 'MATTER Private Pool',
  logoTitle2: 'dFuture Public Pool',
  logoTitle3: 'FIX Private Pool',
  logoTitle4: 'FIX Public Pool',
  logoTitle5: 'DORA Private Pool',
  logoTitle6: 'COOK Private Pool',
  logoTitle7: 'COOK Public Pool',
  aboutProject:
    'Westarter is a platform to bridge cryptocurrency innovators and investors, any innovator can use a standardized interface to launch and manage liquidity bids without a permit.',
  willStart: 'Will start in',
  accessType: 'Access Type',
  private: 'Private',
  public: 'Public',
  svip: 'SVIP',
  svipTips: 'This Pool is only for SVIP',
  privateTips: 'Private pool, only for users in whitelist',
  publicTips: 'Public offering pool, prorated by the amount of funds',
  publicTips1: 'Public offering pool',
  publicTips2:
    'The price of LBP is determined by the real time trading situation in the market, causing crazy volatility. Please notice the risk and do not trade heavily.',
  cannotProject:
    'Sorry, you are not eligable for this project since you are not involved in the whitelist.',
  whiteList: 'Whitelist',
  supportWallet: 'support wallet',
  countdown: 'Fundraising Countdown:',
  myQuota: 'My Quota',
  alreadySubscribed: 'Already Subscribed',
  ParticipantsAreFull: 'Participants are full',
  notInWhitelist: 'Not in whitelist',
  undergoingOver: 'The subscription is over, please wait for settlement',
  flashPool: 'Flash Pool',
  withdrawTip:
    'This is a smart contract address, please do not transfer any token into it.',
  fixAboutProject:
    'Fixed-Rate Protocol (official Site：fixed.finance) is the first and the best stable yield investment protocol on HECO. The all-time monitoring of on-chain data and the intelligent aggregating strategy of savings provide the various investment options for distinctive users along with balancing risks and earnings mechanically. By combining liquidity mining, NFT, and community governance(DAO), FIX Token endowed with intrinsic value which will support Fixed-Rate Protocol to become one of the most stable and the most fruitful application among HECO.',
  doraAboutProjectP1:
    'Dora Factory is an open-source, community-driven venture builder with the mission to empower hackers by creating tools for decentralized venture organizations to thrive.',
  doraAboutProjectP2:
    'The vision of Dora Factory is to build a DAO-as-a-Service infrastructure that empowers every DAO with the right toolkits to better engage with and incentivize open-source developers and hackers on their way of building the next venture.',
  cookAboutProject:
    'Cook Protocol is a completely decentralized asset management platform in the DeFi space built on top of the Ethereum blockchain.',
  chainswapAboutProject1:
    'ChainSwap is a cross-chain asset bridge & application hub for smart chains. ChainSwap allows projects to seamlessly bridge between ETH, BSC and HECO. In the future ChainSwap will be integrating chains and provide full cross-chain solutions between BTC, DOT, SOL and more.',
  chainswapAboutProject2:
    'ChainSwap has closed a $3M strategic funding round with participation from Alameda Research, OK Block Dream Fund (OKEx), NGC Ventures, Spark Digital Capital, Metaconstant Ventures, CMS Holdings, Rarestone Captial, Monday Capital, Continue Capital, SRC Capital, DAO Ventures, Mask network, Particle and Power Law Capital.',
  chainswapAboutProject3: 'Token distribution:',
  chainswapAboutProject4:
    'TOKEN is the token of the ChainSwap platform.The total amount is 100M, 2% of the total amount is 100% unlock token, and will be distributed to public sale and airdrop.Public sale will be held as IDO on 5 different platforms with a total amount of 1M.Airdrop that is given to users is also 1M.',
  blackAboutProject1:
    'BlackHole is an approval-free decentralized & cross-chain burning protocol, innovator of perpetual deflationary blockchain ecosystem.',
  westarterAboutProject1:
    'WeStarter is a cross-chain token initial swap platform which based on HECO network. Through the fluency of the product and sufficient industry resources, it carries the requirement of funds and resources for a variety of asset types. With the role of gatekeeper, through the decentralized incentive and governance mechanism of tokens, to achieve the goal of selecting high-quality assets.',
  leverAboutProject1:
    'Lever is developed to bridge the gap between lending protocols and DEXs, increasing capital efficiency in DeFi.Lever is an open-source margin trading platform where you can lend, borrow and perform leveraged trading to either buy long/sell short an asset in just one place.',
  yfxAboutProject1:
    'YFX.COM is a trading platform provide up to 100x leverage to trade on BTC, ETH and other crypto assets support by ETH, Tron, BSC , Heco,OKEx Chain,Polkadot .By invent QIC-AMM, YFX.COM provide high liquidity and low slippage.',
  O3AboutProject1:
    'O3 Swap, a proprietary cross-chain aggregation protocol, was designed to achieve more efficient trading pathways using 2 solutions. The first solution is Liquidity Aggregation - aggregating the liquidity source across leading Decentralized Exchanges(DEXs) from mainstream blockchains. Another part is Cross-Chain Exchange - implementing cross-chain protocols that allow DeFi users to freely exchange cross-chain assets with one click within their own wallet.',
  corraAboutProject1:
    'Corra is a plug and play platform that allows monetization of digital content using NFT technology. Corra will enable anyone to create and list NFTs for free. This means that regardless of your age, background, or income level, you will have the equal opportunity to monetize your digital content from Facebook, Instagram, Twitter, YouTube, and other Social Media platforms. This includes Facebook posts, tweets, Instagram photos, memes, songs, Tik Tok videos, or just about any form of digital content published online. Whether you are a KOL, a celebrity, public figure, a YouTuber, an Instagram model, a student, or just an individual, you have the same chance to turn your digital content into real money. The platform allows you to share it with your friends, fans, followers, and community, so they can own a piece of your IP to promote your brand and content. In addition, Corra will have a token called CORA that will be used to upvote your listed NFTs. The more upvotes you have for your listed product, the higher ranking and visibility your product will have on Corra’s auction page — this is very similar to paid marketing on Google search results to boost the visibility of your product and to increase the chance of getting a bid. You can raise your upvotes by sharing them with your friends and followers, and get them to upvote for you. Anyone can also upvote your product using CORA tokens. You can obtain CORA tokens from decentralized and centralized crypto exchanges using fiat or crypto currencies.',
  cowAboutProject1:
    "CoinWind is a DeFi smart mining financial platform, which automatically matches pledged tokens to pairs through contracts, uses a minimum hedging free losing strategy to maximize users' income. Therefore, CoinWind effectively solves users' low single-token mining income, large LP mining lose, and other problems.",
  cowAboutProject2:
    'CoinWind aims to build a DeFi digital asset bank, launching a product that combines high-yield, safety, and reliability. CoinWind will manage and transfer different tokens deposited by users into different pools, then manage the funds of a certain token pool to participate in different liquidity mining according to different strategies. At last, make higher benefits for all users. CoinWind can effectively reduce the impermanence loss in liquidity mining through a combination strategy.',
  paulAboutProject1:
    'We designed our foundational architecture based on current cross-chain networks, which has introduced and modified based on the quoting system used by global top organizations in high-frequency automatic trading for single benchmark in multiple trading scenarios. Pledged asset quoting and arbitrage validation are used to build real-time quoting validation mechanism. In extreme situations, a price curve can be generated more smoothly by statistical hypothesis-testing.',
  momatAboutProject1:
    'Launched in December 2020, Moma Protocol offers a proprietary solution to meet the growing demands for liquidity, scalability and speculation in DeFi lending markets.',
  momatAboutProject2:
    'Moma Protocol uses a proprietary smart contract factory to produce, manage, accelerate and aggregate the lending markets, creating an ecosystem that can expand infinitely on lending liquidity and market diversity.',
  momatAboutProject3:
    'Four key components of Moma Protcol are Factory, Launch Pool, Lending Pool and Aggregator. Factory is a smart contract factory that can produce and manage Launch Pool and Lending Pool. Launch Pool is a customizable Pre-Lending Pool designed for community mining. Community tokens can be distributed for any projects through the Launch Pool. Lending Pool is a customizable lending market pool with an over-collateralized loan structure. This pool can support lending and borrowing in any markets. Aggregator is a calculator and analyzer based on Pool and market data. It helps users solve their personalized DeFi demands of lending, borrowing and community mining of crypto assets.',
  momatAboutProject4:
    'Two mechanisms of Price Feed are used: Committee Price Feed and Decentralized Price Feed.',
  momatAboutProject5:
    'Moma Protocol creatively provided its own unique solution in risk management, including four core components: Crypto Asset Risk Rating Database, Whistleblower, Reserve Pool and Staking Management Pool.',
  xNFTAboutProject1:
    'xNFT Protocol, dedicated to realizing the rapid issuance of NFT and automated transaction protocols, is related to ecological products - DigiCenter collection wallet, xNFT Oracle, aggregation market NFTMarketcap, revenue aggregator NFTBank, encryption art gallery NFTPark, etc. ',
  xNFTAboutProject2:
    "xNFT Protocol has acquired the investment from Huobi Ventures, LD Capital, Fundamental Labs, Gateio.labs, Continue Capital, Longling Capital, Dealean Capital, Signum, Anbi Lab, Mars Blockchain, LinkVC, Primitive Ventures, DU Capital, Redline Capital, DragonRoark Fund, 499Block, Crasolum, 7'O Clock Foundation, YBB foundation, LatticeX Foundation and many other well-known institutions around the world.",
  xNFTAboutProject3:
    'Dovey Wan, the founder and partner of Primitive Ventures and a famous blockchain investor, is the xNFT Protocol strategic advisor.',
  plutAboutProject1:
    'DePlutus, next-generation DeFi asset management protocol for both professional and retail investors to initiate, manage and invest in the on-chain crypto funds.',
  phmAboutProject1:
    'Phantom is a cross-chain DeFi protocol that supports synthetic asset generation and NFT issuance. Untilizing smart contracts, Phantom allowing individuals to build synthetic assets (pAssets)  in a decentralized manner with mainstream cryptocurrencies or NFT as collateral. Phantom enables more people to join in a diverse range of investments by lowering the obstacles to entry.',
  phmAboutProject2:
    'Synthetic assets are a tool primarily designed for large investors in the traditional financial industry. The benefit of synthetic assets is that they can imitate real-world asset price movements, allowing investors to participate in other investment categories from the comfort of a unified platform without the cost and risk of asset transfers.',
  hctAboutProject1:
    'HurricaneSwap is a proprietary cross-chain DEX built on Avalanche. With the innovative LP-bridge mechanism (Roke Protocol), HurricaneSwap aims to provide a more efficient method and an advanced cross transaction mechanism to allow users to exchange their assets without network isolation, and provides users with a high-performance and low-fees cross transaction experience.',
  hctAboutProject2:
    'HurricaneSwap innovatively proposes a liquidity cross-chain method: LP cross-chain, where LP provides liquidity of trading pairs in the source chain and the protocol bridge the liquidity to HurricaneSwap. The protocol that implements the LP cross-chain that we call "Roke Protocol."',
  hctAboutProject3:
    'HurricaneStation is the first stop of asset cross-chain. Based on the cross-chain asset (aToken) supported in HurricaneSwap, Station will deploy on the specific chain of the cross-asset. Liquidity Providers could lock their assets in Station, generate LP token, and realize liquidity cross-chain through Roke Protocol to provide liquidity for HurricaneSwap and receive the benefits.',
  hctAboutProject4:
    'HurricaneAlliance aims to narrow the price gap by using a "single token cross-chain bridge" and arbitrage strategy, improving user experience and bringing considerable profit to the arbitrageur. Binding the right to earn arbitrage profit with on-chain governance and nodes can promote nodes to protect the exchange interests spontaneously.',
  related_title1: 'Total IWO funds',
  related_title2: 'Total participated addresses',
  related_title3: 'Total established pools',
  warLBP1: 'Participate {LBPType} Token',
  warLBP2: 'Purchase',
  warLBP3: 'Your Balance: ',
  warLBP4: 'Transaction Settings',
  warLBP5: 'Slippage tolerance',
  warLBP6: 'Succeed',
  warLBP7: 'Please visit MDEX for trading',
  warLBP8: '{token} LPT Mining LIVE. Add your ',
  warLBP9: '{token} LPT from MDEX ',
  warLBP10: 'and get WAR rewards from Farms tab.',
  farm1: 'Farms',
  farm2: 'Stake Liquidity Pool Tokens(LPT) to look for more "sweeties"',
  farm3: 'Stake',
  farm4: 'Available',
  farm5: 'Unstake & Claim',
  farm6: '{coin} Rewards',
  farm7: 'Claim Rewards',
  farm8: 'Countdown',
  farm10: 'Earned',
  farm11: 'Total Stake',
  farm12: 'My Stake',
  farm13: 'Get',
  DayM: 'd',
  HourM: 'h',
  MinM: 'm',
  SecondM: 's',
  farm14: 'Ongoing',
  farm15: 'Staking Amounts',
  farm16: 'Claim',
  farm17: 'Buy',
  farm18: 'Real-time Price',
  farm19: 'WAR Contract Address:',
  farm20: 'Approve',
  farm21: 'Compound',
  farm22: 'Compound {icon} Earned',
  farm23: 'Compound daily',
  farm24: 'SVIP are enabled to join in the special IWO Pool',
  farm25: 'Min Staking {num} {icon}',
  farm26: 'Max Staking {num} {icon}',
  buyPopup1: 'Slippage Tolerance',
  buyPopup2: 'Price Impact',
  buyPopup3: 'Minimum received',
  buyPopup4: 'Trade Fee',
  buyPopup5: 'Powered by MDEX',
  buyPopup6: 'Confirm Swap',
  buyPopup7: 'Estimated',
  buyPopup8: 'Succeed',
  netWork1: 'Choose Network',
  netWork2: 'Choose Wallet',
  netWork3: 'Change Network',
  bridge1: 'Migrate to ',
  bridge2: 'Destination',
  bridge300: 'Notice: Irreversible migration, ',
  bridge301: ' will be transferred to RAW(Matic) forever.',
  bridge4: 'Powered by BlackHole & ChainSwap',
  bridge5: 'History',
  bridge6: 'Assets',
  bridge7: 'From',
  bridge8: 'To',
  bridge9: 'Amounts',
  bridge10: 'withdraw',
  waitingText: 'This process may take some time ... ',
  waitingText1: 'This process may take some time',
  amount: 'amount',
  switchTo: 'Switch to',
  withdraw: 'Withdraw',
  add: 'add',
  to: 'to',
  applicationText1: 'IWO without permission',
  applicationText2: 'Initiate a proposal',
  applicationText3: 'Ongoing',
  applicationText4: 'Finished',
  applicationText5: 'No Data',
  applicationText6: 'Project',
  applicationText7: 'Total Raise',
  applicationText8: 'Vote',
  applicationText9: 'Closed',
  applicationText10: 'Success',
  applicationText11: 'Failed',
  applicationText12: 'Project Information',
  applicationText13:
    'To learn more about APENFT, the chefs advise you to visit their official communication channels:',
  applicationText14:
    'The Syrup Pool: Stake CAKE tokens to earn APENFT’s $NFT tokens!',
  applicationText15: 'Proposals',
  applicationText16: 'My Support',
  applicationText17: 'Claimable',
  applicationText18: 'Soon',
  applicationText19: 'Claimable',

  applicationText26:
    'It is recommended to upload pictures in SVG format, and the recommended resolution for other formats such as PNG is 400 * 400',
  applicationText27:
    'When initiating a voting proposal, {percentage} of this amount must be pledged',
  applicationText28:
    'Please check the entered information carefully, HECO-721 NFT Token will be minted after successful creation, and the information cannot be changed. If you need to change, you need to create a new Project NFT Card',
  applicationText29: 'Create a new',
  applicationText30: 'what is NFT Card?',
  applicationText31: 'current price',
  applicationText32: 'Fundraising',
  applicationText33: 'Recommended',
  applicationText34:
    'The value of the pledged WAR cannot be less than the total amount of funds raised, otherwise it will affect the subsequent fundraising plan',
  applicationText35:
    'The time starts from the voting, the period is {day1} days, and the funds will be released {day2} days after the end of the voting',
  applicationText36: 'Rule',
  applicationText37:
    'If the proposal is within the cycle and equals or exceeds 50% of the total IWO of the project, it is a success, and the voter and the project sponsor (proposer) can claim back their WAR after 5 days',
  applicationText38:
    'If the proposal is less than 50% of the total IWO quota of the project party within the period, it is a failure. 50% of the pledge amount of the proposal will be airdropped to the voting parties. Voters and project sponsors (proposers) can claim back their WAR after 5 days.',
  applicationText39:
    'The NFT Card contains the official project info provided by the project team, then WeStarter casts the information into NFT. All the information cannot be changed if the card finished.',

  adjustmentTips1: 'Dear users,',
  adjustmentTips2:
    'WeStarter recently made a series of operational changes that will now restrict IP access to the following countries:',
  adjustmentTips3:
    "Japan, Cuba, Iran, Mainland China, Hong Kong China, Macao China, The Democratic People's Republic of Korea, Sudan, Syria, United States of America and its Overseas Territories (American Samoa, Guam, Northern Mariana Islands, Puerto Rico and THE United States Virgin Islands), Canada and Singapore.",
  adjustmentTips4: 'Thank you for supporting WeStarter!',
  adjustmentTips5: "I'm not from these regions.",
}
export default en_US
